import React from 'react';

import { ReactComponent as Logo} from './logo.svg';
import { DisclimerOffer } from "./DisclimerOffer";

import './footer.scss';


export const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__content">
        <div className="footer__copyright">
          <Logo className="footer__logo" />
          <small className="footer__copyright-year">© {(new Date()).getFullYear()}</small>
        </div>
        <div  className="footer__protect">
          <a
            href="https://www.ecredit.one/personal_data_processing_policy"
            target="_blank"
          >
            Защита персональных данных
          </a>
          <a
            href="https://www.ecredit.one/user_agreement"
            target="_blank"
          >
            Пользовательское соглашение
          </a>

          <DisclimerOffer/>

        </div>
      </div>
    </div>
  );
};
